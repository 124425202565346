body {
  font-family: "Helvetica", Verdana, Tahoma, "Trebuchet MS", "Lucida Sans", sans-serif;
  background-color: black; /* Cor de fundo padrão */
  color: midnightblue;
  font-weight: 600;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* App.css */
.App {
  background-image: url("../src/assets/images/image-00003.jpg"); /* Imagem de fundo */
  background-size: cover; /* Faz com que a imagem cubra toda a tela */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Evita que a imagem se repita */
  min-height: 100vh; /* Garante que o fundo ocupe a tela inteira */
  display: flex;
  flex-direction: column;
}

.content-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
