/* Header.css */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding-top: 0%;
  padding-bottom: 0%;
  padding-left: 0%;
  padding-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 40px; /* Ajuste para uma altura menor */
  margin-left: 20px;
  padding-top: 2px;
}

.contact-btn {
  background-color: #ff0000;
  color: white;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  padding: 6px 12px; /* Reduz o tamanho do botão */
  border-radius: 5px;
  transition: background-color 0.4s;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
  margin-right: 20px;
}

.contact-btn:hover {
  background-color: #15740d;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
}
