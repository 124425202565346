.contact-form {
    max-width: 500px;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7); /* Fundo transparente para o formulário */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); /* Sombra leve */
    color: white;
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ocupa a altura total da tela */
    background-size: cover; /* Faz a imagem de fundo cobrir a tela */
    background-position: center; /* Centraliza a imagem de fundo */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
    padding: 0px;
    color: white;
}
.form-group {
    margin-bottom: 15px; /* Espaçamento entre os campos */
}

label {
    display: block; /* Faz o label ocupar toda a linha */
    margin-bottom: 5px; /* Espaçamento abaixo do label */
}

input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.9); /* Fundo leve nos campos de entrada */
    color: black;
}

textarea {
    resize: vertical; /* Permite redimensionar verticalmente */
}


.centered-button {
    display: block; /* Faz o botão se comportar como um bloco */
    margin: 0px auto; /* Adiciona margens superior/inferior e centraliza horizontalmente */
    background-color: #ff0000; /* Cor de fundo do botão */
    color: white; /* Cor do texto do botão */
    font-weight: 600;
    padding: 10px 20px; /* Espaçamento interno do botão */
    border: none; /* Remove borda padrão */
    border-radius: 5px; /* Cantos arredondados do botão */
    cursor: pointer; /* Muda o cursor ao passar sobre o botão */
    transition: background-color 0.3s; /* Transição suave ao passar o mouse */
    text-decoration: none;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.centered-button:hover {
    background-color: #15740d; /* Cor do botão ao passar o mouse */
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);

}


/* Estilo do modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    color: blue;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-content p {
    margin-bottom: 15px;
}

.modal-content button {
    padding: 10px 20px;
    background-color: #ff0000;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #15740d;
}
