.footer-container {
  align-items: center;
  background-color: transparent;
  color: black;
  display: flex;
  left: 0;
  margin: 0 auto; /* Centraliza o footer */
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.8); /* Mantenha o border-top */
  justify-content: center; /* Centraliza o conteúdo */
  position: fixed; /* Mantém o footer fixo */
  bottom: 0; /* Posiciona o footer na parte inferior */
  width: 100%; /* Largura total */
  z-index: 1000; /* Garante que fique sobre outros elementos */
}
